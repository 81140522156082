import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import HeaderText from "../common/HeaderText";
import HelperText from "../common/HelperText";
import { HEADER_LEVEL, NERDWALLET_SIGNUP } from "../../utils/commonConst";
import {
  Breakpoints,
  Colors,
  FontSizes,
  Spacing,
} from "../../styles/styleConstants";
import { navigateTo } from "../../utils/commonUtil";

function NerdWalletNoWorries(props) {
  const { whatToDo, whatYouGet } = props ?? {};
  const { title, data } = whatYouGet;
  const { title: whatToDoTitle, data: whatToDoData } = whatToDo;

  return (
    <WhatYouGetWrapper>
      <Container>
        <HeaderText level={HEADER_LEVEL.LEVEL_2}>{whatToDoTitle}</HeaderText>
        <WhatYouGetCardWrapper>
          {whatToDoData.map((element) => {
            return (
              <WhatYouGetCard fullWidth>
                <ImageWrapper>
                  <Image
                    src={element.logo_path}
                    style={{
                      minWidth: "64px",
                      maxWidth: "64px",
                    }}
                  />
                </ImageWrapper>
                <WhatYouGetDetails>
                  <HeaderText level={HEADER_LEVEL.LEVEL_5}>
                    {element.title}
                  </HeaderText>
                  <HelperText
                    style={{ fontSize: FontSizes.md, lineHeight: "24px" }}
                  >
                    {element.detailText}
                  </HelperText>
                </WhatYouGetDetails>
              </WhatYouGetCard>
            );
          })}
        </WhatYouGetCardWrapper>
      </Container>
      <Container>
        <HeaderText level={HEADER_LEVEL.LEVEL_2}>{title}</HeaderText>
        <WhatYouGetCardWrapper>
          {data.map((element) => {
            return (
              <WhatYouGetCard>
                <ImageWrapper>
                  <Image
                    src={element.logo_path}
                    style={{
                      minWidth: "64px",
                      maxWidth: "64px",
                    }}
                  />
                </ImageWrapper>
                <WhatYouGetDetails>
                  <HeaderText
                    level={HEADER_LEVEL.LEVEL_5}
                    style={{ margin: `${Spacing.xs} 0`, width: "85%" }}
                  >
                    {element.title}
                  </HeaderText>
                  <HelperText
                    style={{ fontSize: FontSizes.md, lineHeight: "24px" }}
                  >
                    {element.detailText}
                  </HelperText>
                </WhatYouGetDetails>
              </WhatYouGetCard>
            );
          })}
        </WhatYouGetCardWrapper>
        <HeaderText
          level={HEADER_LEVEL.LEVEL_3}
          style={{ margin: `${Spacing.xl} 0` }}
        >
          All for about $0.50 / day.
        </HeaderText>
        <CtaButton
          onClick={(evt) => {
            navigateTo(NERDWALLET_SIGNUP);
            evt.preventDefault();
            evt.stopPropagation();
          }}
          ctaType="primary"
          style={{
            backgroundColor: Colors.nerdwalletBlue,
            marginTop: Spacing.xxxl,
            height: "48px",
          }}
          color={Colors.white}
        >
          <HeaderText
            level={HEADER_LEVEL.LEVEL_4_1}
            style={{ color: Colors.white }}
          >
            Get your free plan
          </HeaderText>
        </CtaButton>
      </Container>
    </WhatYouGetWrapper>
  );
}

export default NerdWalletNoWorries;

const WhatYouGetWrapper = styled.div`
  margin-top: 45px;
  margin-bottom: 45px;

  @media (max-width: ${Breakpoints.lg}) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const WhatYouGetCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.xs} 0;
  flex-wrap: wrap;
  margin-top: ${Spacing.lg};
  gap: 20px;

  @media (max-width: ${Breakpoints.lg}) {
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    gap: ${Spacing.lg};
  }
`;

const WhatYouGetCard = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${Spacing.md} 0;
  background-color: #fff;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "32%")};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px;

  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.lg};
  }
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: ${Breakpoints.lg}) {
    margin: auto;
    width: 100%;
  }
`;

const WhatYouGetDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${Spacing.md};

  @media (max-width: ${Breakpoints.sm}) {
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  margin-left: ${Spacing.md};

  @media (max-width: ${Breakpoints.sm}) {
    margin-left: 0;
  }
`;
