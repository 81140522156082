import React from "react";
import { Container, Col } from "react-bootstrap";
import styled from "styled-components";

import CtaButton from "../common/CtaButton";
import HeaderText from "../common/HeaderText";
import HelperText from "../common/HelperText";
import Image from "../common/Image";
import { navigateTo } from "../../utils/commonUtil";
import { HEADER_LEVEL, NERDWALLET_SIGNUP } from "../../utils/commonConst";
import {
  Breakpoints,
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
  Spacing,
} from "../../styles/styleConstants";

export default function NerdWalletHero() {
  return (
    <HeroWrapper>
      <Container className="partner-hero-container" style={{ marginLeft: 0 }}>
        <Col className="text-container">
          <Title>
            Get a Free
            <br /> Personalized Financial Plan in 15 Minutes
          </Title>
          <div className="my-4 textBig">
            Get a free financial plan that you can start taking
            <br /> action on immediately.
          </div>
          <CtaWrapper>
            <CtaButton
              onClick={(evt) => {
                navigateTo(NERDWALLET_SIGNUP);
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
              style={{ backgroundColor: Colors.nerdwalletBlue, height: "54px" }}
              color={Colors.white}
            >
              <HeaderText
                level={HEADER_LEVEL.LEVEL_4_1}
                style={{ color: Colors.white }}
              >
                Get your free plan
              </HeaderText>
            </CtaButton>
          </CtaWrapper>
          <HelperText
            style={{
              color: Colors.neutralMedDark,
              marginTop: Spacing.md,
              fontSize: FontSizes.lg,
              lineHeight: "30px",
            }}
          >
            No credit card required.
          </HelperText>
        </Col>
        <Col className="pr-0">
          <Image className="HeroImage" src="partner/img-header-all@2x.png" />
        </Col>
      </Container>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  width: 100% !important;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  .HeroImage {
    width: 100%;
  }

  .partner-hero-container {
    display: contents;
    width: 50vw;
    padding-right: 0 !important;
    margin-top: 90px;

    @media (max-width: ${Breakpoints.sm}) {
      display: flex;
      gap: ${Spacing.lg};
      flex-direction: column;
      width: 100vw;
      margin-top: 0px;
    }
  }

  .text-container {
    padding-left: 15vw !important;
    display: flex;
    flex-direction: column;

    @media (max-width: 1620px) {
      padding-left: 13vw !important;
    }
    @media (max-width: 1440px) {
      padding-left: 10vw !important;
    }
    @media (max-width: ${Breakpoints.lg}) {
      padding-left: 13vw !important;
    }
    @media (max-width: ${Breakpoints.md}) {
      padding-left: 13vw !important;
    }
    @media (max-width: ${Breakpoints.sm}) {
      margin-right: ${Spacing.md} !important;
      padding-left: ${Spacing.md} !important;
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin: 24px 0px;
    flex-direction: column;

    .partner-hero-container {
      width: 100%;
      padding: 0 !important;
    }
  }
`;

const CtaWrapper = styled.div``;

const Title = styled.div`
  font-family: ${FontFamilies.title};
  font-style: normal;
  font-weight: ${FontWeights.normal};
  font-size: 32px;
  line-height: 1.2;
  color: ${Colors.almostBlack};
  width: 100%;

  span {
    color: ${Colors.nerdwalletBlue};
    font-weight: 500;
  }

  @media only screen and (min-width: ${Breakpoints.md}) {
    width: 80%;
    font-size: 54px;
  }
`;

const BulletList = styled.ul`
  padding-inline-start: 20px;
  margin: ${Spacing.xl} 0 ${Spacing.xl};

  @media only screen and (min-width: ${Breakpoints.md}) {
    width: 30vw;
  }
`;

const ListItem = styled.li`
  font-size: ${FontSizes.md};
  color: ${Colors.nerdwalletBlue};

  &:not(:last-child) {
    margin-bottom: ${Spacing.md};
  }

  span {
    color: ${Colors.almostBlack};
    span {
      font-weight: ${FontWeights.bolder};
    }
  }

  @media only screen and (min-width: ${Breakpoints.md}) {
    font-size: ${FontSizes.lg};
  }
`;
