import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

import { HowUpriseWorksContent } from "../../data/homeData";
import titleBackground from "../../images/nerdwallet/how_uprise_works_title_background.png";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import HeaderText from "../common/HeaderText";
import {
  HEADER_LEVEL,
  NERDWALLET_SIGNUP,
  OTHER_APP_PAGES,
} from "../../utils/commonConst";
import { getAppUrlByModule, navigateTo } from "../../utils/commonUtil";
import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from "../../styles/styleConstants";

const numberImages = [
  "nerdwallet/blue_one_icon.png",
  "nerdwallet/blue_two_icon.png",
  "nerdwallet/blue_three_icon.png",
  "nerdwallet/blue_four_icon.png",
];

export function NerdwalletHowUpriseWorks() {
  return (
    <Container>
      <HowUpriseWorksWrapper>
        <LeftSide>
          <HowUpriseWorksComponent>
            <HowUpriseWorksTitle>
              {HowUpriseWorksContent.title}
            </HowUpriseWorksTitle>
          </HowUpriseWorksComponent>
          <HowUpriseWorksComponent>
            <HelperTextWrapper>
              {HowUpriseWorksContent.helperTextSteps.map((step, indx) => {
                return (
                  <HowUpriseWorksHelperText
                    key={`helper-text-uprise-work-${indx}`}
                  >
                    <Image
                      src={"nerdwallet/gray_x_icon.png"}
                      imgStyle={{ objectFit: "contain" }}
                      alt={`gray-x-icon-how-uprise-work-${indx}`}
                      style={{ width: "20px", height: "20px" }}
                    />
                    {step}
                  </HowUpriseWorksHelperText>
                );
              })}
            </HelperTextWrapper>
          </HowUpriseWorksComponent>
          <CtaButtonWrapper className="cta-button-desktop">
            <CtaButton
              onClick={(evt) => {
                navigateTo(NERDWALLET_SIGNUP);
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
              style={{ backgroundColor: Colors.nerdwalletBlue, height: "48px" }}
              color={Colors.white}
            >
              <HeaderText
                level={HEADER_LEVEL.LEVEL_4_1}
                style={{ color: Colors.white }}
              >
                Get your free plan
              </HeaderText>
            </CtaButton>
          </CtaButtonWrapper>
          <CtaButtonWrapper className="cta-button-mobile">
            <CtaButton
              onClick={(evt) => {
                navigateTo(getAppUrlByModule(OTHER_APP_PAGES.SIGNUP));
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
              style={{ backgroundColor: Colors.nerdwalletBlue, height: "48px" }}
              color={Colors.white}
              isStretchToFullWidth
            >
              <HeaderText
                level={HEADER_LEVEL.LEVEL_4_1}
                style={{ color: Colors.white }}
              >
                Get your free plan
              </HeaderText>
            </CtaButton>
          </CtaButtonWrapper>
        </LeftSide>
        <RightSide>
          <HowUpriseWorksComponent>
            <StepsWrapper>
              {HowUpriseWorksContent.contentSteps.map((step, indx) => {
                return (
                  <HowUpriseWorksContentWrapper
                    key={`content-uprise-work-${indx}`}
                  >
                    <NumberWrapper>
                      <Image
                        src={numberImages[indx]}
                        imgStyle={{ objectFit: "contain" }}
                        alt={`blue-number-icon-how-uprise-work-${indx}`}
                        style={{ width: "32px", height: "32px" }}
                      />
                    </NumberWrapper>
                    <HowUpriseWorksContentInfo>
                      <ContentTitle>{step.title}</ContentTitle>
                      <ContentBody>{step.content}</ContentBody>
                    </HowUpriseWorksContentInfo>
                  </HowUpriseWorksContentWrapper>
                );
              })}
            </StepsWrapper>
          </HowUpriseWorksComponent>
        </RightSide>
      </HowUpriseWorksWrapper>
    </Container>
  );
}

const HowUpriseWorksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 48px;
  padding-right: 48px;
  box-shadow: 0px 4px 10px 2px ${Colors.nerdWalletBoxShadow};
  background-color: ${Colors.white};
  gap: 8px;
  .cta-button-mobile {
    @media (min-width: ${Breakpoints.sm}) {
      display: none;
    }
  }

  .cta-button-desktop {
    @media (max-width: ${Breakpoints.sm}) {
      display: none;
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const HowUpriseWorksComponent = styled.div`
  width: 100%;
`;

const HowUpriseWorksTitle = styled.div`
  background: url(${titleBackground});
  height: 65px;
  width: 415px;
  font-size: 44px;
  font-weight: ${FontWeights.bold};
  letter-spacing: 2px;
  color: ${Colors.almostBlack};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${Spacing.xs};

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 24px;
    font-weight: ${FontWeights.bolder};
    height: 44px;
    width: 95%;
  }
`;

const HelperTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 8px;
  margin-bottom: 72px;

  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const HowUpriseWorksHelperText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Colors.almostBlack};
  font-size: ${FontSizes.sm};
  gap: 8px;
  font-weight: ${FontWeights.normal};
`;

const HowUpriseWorksContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

const HowUpriseWorksContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CtaButtonWrapper = styled.div``;

const NumberWrapper = styled.div``;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
  }
`;

const RightSide = styled.div`
  width: 50%;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    margin-top: 24px;
  }
`;

const ContentTitle = styled.div`
  color: ${Colors.almostBlack};
  font-size: ${FontSizes.md};
  font-weight: ${FontWeights.bold};
`;

const ContentBody = styled.div`
  color: ${Colors.almostBlack};
  font-size: ${FontSizes.sm};
  font-weight: ${FontWeights.normal};
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
