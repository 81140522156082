import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

import Image from "../common/Image";
import BodyText from "../common/BodyText";
import { headerStyle } from "../common/HeaderText";
import { HEADER_LEVEL } from "../../utils/commonConst";
import {
  Breakpoints,
  Colors,
  FontFamilies,
  Spacing,
} from "../../styles/styleConstants";

export default function NerdwalletFaq({ content }) {
  const faqs = content.map((faq) => {
    return FaqQuestion(faq);
  });
  return (
    <Container id="faq">
      <FaqTitleWrapper level={HEADER_LEVEL.LEVEL_1}>
        {" "}
        Frequently asked questions{" "}
      </FaqTitleWrapper>
      {faqs}
    </Container>
  );
}

export function FaqQuestion(question) {
  const { isExpanded, questionKey, id } = question ?? {};
  const [expanded, setExpanded] = useState(isExpanded ?? false);

  const minusIcon = () => {
    return (
      <Image
        src="home/chevron-up-blue-ic.png"
        style={{
          width: "24px",
          height: "24px",
          objectFit: "contain",
        }}
      />
    );
  };

  const plusIcon = () => {
    return (
      <Image
        src="home/chevron-down-blue-ic.png"
        style={{
          width: "24px",
          height: "24px",
          objectFit: "contain",
        }}
      />
    );
  };

  return (
    <FaqQuestionWrapper expanded={expanded} key={questionKey} id={id}>
      <FaqQuestionHeader
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      >
        <FaqQuestionTitle>{question.question}</FaqQuestionTitle>
        <FaqQuestionExpandIcon>
          {expanded ? minusIcon() : plusIcon()}
        </FaqQuestionExpandIcon>
      </FaqQuestionHeader>
      {expanded && (
        <FaqDetailWrapper>
          <FaqPrimaryDetail>{question.answer.primary}</FaqPrimaryDetail>
          <BodyText style={{ whiteSpace: "pre-line" }}>
            {question.answer.secondary}
          </BodyText>
        </FaqDetailWrapper>
      )}
    </FaqQuestionWrapper>
  );
}

const FaqTitleWrapper = styled.div`
  ${headerStyle[HEADER_LEVEL.LEVEL_1]}
  color: ${Colors.almostBlack};
`;

const FaqQuestionWrapper = styled.article`
  padding: ${Spacing.lg};
  margin: 16px 0px;
  border: 2px solid ${Colors.nerdwalletBorderBlue};
  border-radius: 4px;
  background-color: ${Colors.white};
  @media (min-width: ${Breakpoints.lg}) {
    padding: ${Spacing.lg};
  }
`;

const FaqQuestionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.expanded && "padding-bottom: 24px; border-bottom: 1px solid #D1D3D4"};
  user-select: none;
`;

const FaqQuestionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.almostBlack};

  @media (min-width: ${Breakpoints.lg}) {
    font-size: 20px;
  }
`;

const FaqQuestionExpandIcon = styled.button`
  background: transparent;
  border-color: transparent;
`;

const FaqDetailWrapper = styled.div`
  margin: ${Spacing.lg};
`;

const FaqPrimaryDetail = styled.div`
  font-family: ${FontFamilies};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.almostBlack};
  margin-bottom: ${Spacing.lg};
`;
