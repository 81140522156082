import React from "react";
import styled from "styled-components";

import titleBackground from "../../images/nerdwallet/what_we_cover_title_background.png";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import { navigateTo } from "../../utils/commonUtil";
import { STILL_QUESTIONS_NERDWALLET_CALENDLY_LINK } from "../../utils/commonConst";
import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";
import { NERDWALLET_COACH_HELLO_EMAIL } from "../../data/commonData";

export default function NerdwalletStillQuestions() {
  return (
    <StillQuestionsWrapper>
      <ContentWrapper>
        <LeftSide>
          <TitleText>Still have questions?</TitleText>
          <BodyText>
            Set up a 15-minute zoom call with a member of our team to get them
            answered.
          </BodyText>
          <CtaButton
            onClick={(evt) => {
              navigateTo(STILL_QUESTIONS_NERDWALLET_CALENDLY_LINK);
              evt.preventDefault();
              evt.stopPropagation();
            }}
            ctaType="secondary"
            style={{ borderColor: Colors.nerdwalletBlue }}
            color={Colors.nerdwalletBlue}
            background={Colors.transparent}
          >
            <ScheduleStrong>Schedule a call now</ScheduleStrong>
          </CtaButton>
          {/* <div>
            <BodyText>Or email us any time at</BodyText>
            <BodyText
              style={{
                color: Colors.nerdwalletBlue,
                textDecoration: "underline",
                fontWeight: FontWeights.bolder,
              }}
            >
              {NERDWALLET_COACH_HELLO_EMAIL}
            </BodyText>
          </div> */}
        </LeftSide>
        <RightSide>
          <Image
            src={"nerdwallet/still_questions_calendar.png"}
            alt="schedule-a-call"
            imgStyle={{ objectFit: "contain" }}
            style={{
              height: "233px",
              width: "484px",
            }}
          />
        </RightSide>
      </ContentWrapper>
    </StillQuestionsWrapper>
  );
}

const StillQuestionsWrapper = styled.div`
  padding: 160px;
  background-color: ${Colors.nerdwalletBackgroundBlue};
  height: 629px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${Breakpoints.sm}) {
    padding: 16px 24px;
    height: auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 160px;
  height: 309px;

  @media (max-width: ${Breakpoints.sm}) {
    gap: 0px;
    height: auto;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const TitleText = styled.div`
  background-image: url(${titleBackground});
  color: ${Colors.white};
  font-size: ${FontSizes.xxxl};
  font-weight: ${FontWeights.bolder};
  line-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 467px;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    font-size: ${FontSizes.xl};
  }
`;

const BodyText = styled.div`
  color: ${Colors.almostBlack};
  font-size: ${FontSizes.lg};
  margin-top: 32px;
  margin-bottom: 16px;
`;

const RightSide = styled.div`
  width: 50%;

  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`;

const ScheduleStrong = styled.strong`
  text-transform: uppercase;
  font-weight: ${FontWeights.bolder};
`;
