import React from "react";
import styled from "styled-components";

import { WhatWeCoverContent } from "../../data/homeData";
import titleBackground from "../../images/nerdwallet/what_we_cover_title_background.png";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import HeaderText from "../common/HeaderText";
import {
  HEADER_LEVEL,
  NERDWALLET_SIGNUP,
  OTHER_APP_PAGES,
} from "../../utils/commonConst";
import { getAppUrlByModule, navigateTo } from "../../utils/commonUtil";
import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";

export default function NerdwalletWhatWeCover() {
  return (
    <NerdwalletCoverContainer>
      <ContentWrapper>
        <TitleWrapper>
          <TitleText>What we cover</TitleText>
        </TitleWrapper>
        <AllSteps>
          <LeftSide>
            <ContentSteps>
              {WhatWeCoverContent.left.map((step, indx) => {
                return (
                  <ContentPiece key={`what-we-cover-${indx}`}>
                    <Image
                      src={"nerdwallet/nerdwallet_blue_check.png"}
                      imgStyle={{ objectFit: "contain" }}
                      alt={`blue-number-icon-${indx}`}
                      style={{ width: "20px", height: "20px" }}
                    />
                    {step}
                  </ContentPiece>
                );
              })}
            </ContentSteps>
          </LeftSide>
          <RightSide>
            <ContentSteps>
              {WhatWeCoverContent.right.map((step, indx) => {
                return (
                  <ContentPiece key={`what-we-cover-right-${indx}`}>
                    <Image
                      src={"nerdwallet/nerdwallet_blue_check.png"}
                      imgStyle={{ objectFit: "contain" }}
                      alt={`blue-number-icon-right-${indx}`}
                      style={{ width: "20px", height: "20px" }}
                    />
                    {step}
                  </ContentPiece>
                );
              })}
            </ContentSteps>
          </RightSide>
        </AllSteps>
        <CtaButtonWrapper className="cta-button-desktop">
          <CtaButton
            onClick={(evt) => {
              navigateTo(NERDWALLET_SIGNUP);
              evt.preventDefault();
              evt.stopPropagation();
            }}
            ctaType="primary"
            style={{ backgroundColor: Colors.nerdwalletBlue, height: "48px" }}
            color={Colors.white}
          >
            <HeaderText
              level={HEADER_LEVEL.LEVEL_4_1}
              style={{ color: Colors.white }}
            >
              Get your free plan
            </HeaderText>
          </CtaButton>
        </CtaButtonWrapper>
        <CtaButtonWrapper className="cta-button-mobile">
          <CtaButton
            onClick={(evt) => {
              navigateTo(NERDWALLET_SIGNUP);
              evt.preventDefault();
              evt.stopPropagation();
            }}
            ctaType="primary"
            style={{
              backgroundColor: Colors.nerdwalletBlue,
              width: "100%",
              height: "48px",
            }}
            color={Colors.white}
            isStretchToFullWidth
          >
            <HeaderText
              level={HEADER_LEVEL.LEVEL_4_1}
              style={{ color: Colors.white }}
            >
              Get your free plan
            </HeaderText>
          </CtaButton>
        </CtaButtonWrapper>
      </ContentWrapper>
      <RightImageWrapper>
        <Image
          src={"nerdwallet/what_we_cover_right.png"}
          imgStyle={{ objectFit: "contain" }}
          alt={`what-we-cover-right`}
        />
      </RightImageWrapper>
    </NerdwalletCoverContainer>
  );
}

const NerdwalletCoverContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  background-color: ${Colors.nerdwalletBackgroundBlue};
  padding-left: 15%;

  @media (max-width: ${Breakpoints.sm}) {
    padding: 0;
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  margin-top: 96px;
  margin-bottom: 96px;

  .cta-button-mobile {
    width: 100%;

    @media (min-width: ${Breakpoints.sm}) {
      display: none;
    }
  }

  .cta-button-desktop {
    @media (max-width: ${Breakpoints.sm}) {
      display: none;
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    width: 90%;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 16px;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  background-image: url(${titleBackground});
  color: ${Colors.white};
  font-size: 44px;
  font-weight: ${FontWeights.bold};
  height: 65px;
  width: 353px;
  line-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    padding: none;
    font-size: 38px;
  }
`;

const ContentSteps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ContentPiece = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-size: ${FontSizes.md};
  color: ${Colors.almostBlack};
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 16px;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
  }
`;

const CtaButtonWrapper = styled.div``;

const RightImageWrapper = styled.div`
  width: 326px;
  margin-top: 48px;

  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`;

const AllSteps = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 960px;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }
`;

const TitleText = styled.strong``;
