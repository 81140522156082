import React from "react";
import styled from "styled-components";

import CurveTopBg from "../../images/home/gray-curve-top-bg.png";
import {
  NerdWalletTrustedBy,
  WhatYouGetAsNerdWalletContent,
  ExpertInPocketContent,
  NerdwalletFaqData,
  WhatToDoAsNerdWalletContent,
} from "../../data/homeData";

import Image from "../common/Image";
import LogoBar from "../common/logoBar";
import Layout from "../layouts/nerdwalletLayout";
import ExpertInPocket from "../home/ExpertInPocket";
import NerdwalletFaq from "./NerdwalletFaq";
import NerdwalletHero from "./NerdwalletHero";
import NerdwalletHeader from "./NerdwalletHeader";
import NerdwalletFooter from "./NerdwalletFooter";
import NerdwalletFeedback from "./NerdwalletFeedback";
import NerdWalletNoWorries from "./NerdWalletNoWorries";
import NerdwalletWhatWeCover from "./NerdwalletWhatWeCover";
import NerdwalletStillQuestions from "./NerdwalletStillQuestions";
import NerdWalletPaymentDetails from "./NerdWalletPaymentDetails";
import { NerdwalletHowUpriseWorks } from "./NerdwalletHowUpriseWorks";
import { isNonEmpty, parseReqParams } from "../../utils/commonUtil";
import { CLAIR_SIGNUP_CODE, QUERY_PARAMS } from "../../utils/commonConst";
import { Breakpoints, Colors, Spacing } from "../../styles/styleConstants";

const nerdwalletCoachLogo = "nerdwallet/nerdwallet_coach_logo.png";

export default function NerdwalletPartnership() {
  const signupCodeReqParamValue = parseReqParams(QUERY_PARAMS.SIGNUP_CODE);
  const signupCode = `${CLAIR_SIGNUP_CODE}${
    isNonEmpty(signupCodeReqParamValue) ? ` - ${signupCodeReqParamValue}` : ""
  }`;

  return (
    <Layout includeHeader={false} includeFooter={false}>
      {/* {process.env.GATSBY_SHOW_BANNER === "true" && <Banner />} */}
      <WrapperBG>
        <HeroWrapper>
          <NerdwalletHeader
            hideMoreInfo={true}
            customLogoDesktop={nerdwalletCoachLogo}
          />
          <NerdwalletHero signupCode={signupCode} />
          {/* <Image
          className="HeroImage"
          // src="nerdwallet/nerdwallet_hero_illustration.png"
          src="partner/img-header-all@2x.png"
          /> */}
        </HeroWrapper>
        <TrustedBySectionWrapper>
          <LogoBar content={NerdWalletTrustedBy} signupCode={signupCode} />
        </TrustedBySectionWrapper>
        <NerdWalletNoWorries
          whatYouGet={WhatYouGetAsNerdWalletContent}
          whatToDo={WhatToDoAsNerdWalletContent}
        />
        <ExpertInPocketWrapper>
          <ExpertInPocket expertInPocketData={ExpertInPocketContent} />
        </ExpertInPocketWrapper>
      </WrapperBG>
      <TrustWrapperBG>
        <HowUpriseWorksWrapper>
          <NerdwalletHowUpriseWorks />
        </HowUpriseWorksWrapper>
        <CustomerFeedbackWrapper>
          <NerdwalletFeedback />
        </CustomerFeedbackWrapper>
      </TrustWrapperBG>
      <WhatWeCoverWrapper>
        <NerdwalletWhatWeCover />
      </WhatWeCoverWrapper>
      <FaqWrapper>
        <NerdWalletPaymentDetails />
        <NerdwalletFaq content={NerdwalletFaqData} />
      </FaqWrapper>
      <StillQuestionsWrapper>
        <NerdwalletStillQuestions />
      </StillQuestionsWrapper>
      <FooterWrapper>
        <NerdwalletFooter />
      </FooterWrapper>
    </Layout>
  );
}

const HeroWrapper = styled.div`
  margin: 0 0 ${Spacing.xxxl};
  .HeroImage {
    width: 100%;
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin: 0 0 ${Spacing.xl};
  }
`;

const WrapperBG = styled.div`
  position: relative;
  background: linear-gradient(
    ${Colors.white},
    ${Colors.nerdwalletBackgroundBlue}
  );
`;

const CustomerFeedbackWrapper = styled.div`
  @media (max-width: ${Breakpoints.sm}) {
    padding-top: ${Spacing.sm};
  }
`;

const TrustWrapperBG = styled.div`
  background-color: ${Colors.white};
`;

const FooterWrapper = styled.div`
  background-color: white;
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

const TrustedBySectionWrapper = styled.div`
  margin-top: 90px;

  @media (max-width: ${Breakpoints.lg}) {
    margin-top: ${Spacing.xxl};
  }
`;

const HowUpriseWorksWrapper = styled.div`
  padding: ${Spacing.xxxxxl};
  padding-top: 160px;
  padding-bottom: 160px;
  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.xl} 0;
  }
`;

const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 125px 0 ${Spacing.xxxxxl};
  background: linear-gradient(
    to right,
    ${Colors.white},
    ${Colors.nerdwalletBackgroundBlue}
  );

  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.xl} 0;
  }
`;

const ExpertInPocketWrapper = styled.div`
  margin-top: ${Spacing.xxxxl};
`;

const WhatWeCoverWrapper = styled.div``;

const StillQuestionsWrapper = styled.div``;
